import axios from './custom-axios';

const POS = {
  getAll: (config) => {
    return axios.get('pos', config);
  },
  switchPOS: (posId) => {
    return axios.put(`pos/switch/${posId}`);
  },
};

export default POS;
