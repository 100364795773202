import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import globalStyle from './global';

export const primary = {
  main: '#0B4668',
  light: '#006D8A',
  lighter: '#0b466814',
  lightest: '#0b466814',
};

let theme = createTheme({
  palette: {
    white: '#FFF',
    primary,
    secondary: {
      main: red[700],
    },
    drawer: {
      background: 'white',
      hover: '#ffffff0f',
      selected: primary.light,
      subSelected: primary.lighter,
      selectedColor: 'white',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
        elevation2: {
          boxShadow:
            '1px 1px 18px 1px rgb(0 0 0 / 10%), -1px 0px 4px 0px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
  typography: {
    fontFamily: ['Roboto', 'KhmerOS Siemreap'].join(','),
  },
});

//overwrite globalStyle
theme.components.MuiCssBaseline.styleOverrides = globalStyle(theme);

theme = responsiveFontSizes(theme);

export default theme;
