import { Box, ListItem, Divider } from '@mui/material';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.scss';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  text: {
    marginRight: '10px',
    minHeight: '3em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& span': {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
  },
  action: {
    width: '70%',
    maxWidth: '100px',
  },
}));

const SwipeableListItem = ({ children, renderActions, divider, ...props }) => {
  const classes = useStyles();

  // store swiper instance
  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => {
    try {
      swiper.slideTo(index);
    } catch (error) {
      console.log(error);
    }
  };
  const closeSlide = () => slideTo(0);

  return (
    <React.Fragment>
      <Swiper slidesPerView='auto' onSwiper={setSwiper} resistanceRatio={0}>
        <SwiperSlide>
          <ListItem disableGutters style={{ padding: '8px' }} button {...props}>
            {children}
          </ListItem>
        </SwiperSlide>
        <SwiperSlide className={classes.action}>
          <Box display='flex' justifyContent='center' alignItems='center'>
            {renderActions && renderActions(closeSlide)}
          </Box>
        </SwiperSlide>
      </Swiper>
      {divider && <Divider />}
    </React.Fragment>
  );
};

export default React.memo(SwipeableListItem);
