import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_EN } from './en/translation';
import MENU_EN from './en/menu.json';
// import { TRANSLATIONS_KH } from "./kh/translation";

const resources = {
  en: {
    page: TRANSLATIONS_EN,
    menu: MENU_EN,
  },
  // kh: {
  //   page: TRANSLATIONS_KH,
  //   menu: MENU_KH,
  // },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  //   default language
  defaultNS: 'page',
  resources,
  lng: 'en',
});
