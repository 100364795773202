import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/contexts/AuthProvider';
import Logo from '../Logo';
import Menu from '../Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Profile from '../Profile';
import { FaCashRegister } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/routes';

const DrawerStyle = styled(Drawer)(({ theme, ...props }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: props.width,
    position: 'relative',
    backgroundColor: theme.palette.drawer.background,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const DrawerComponent = ({ drawerWidth, state, handleClose }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleFullScreen = () => {
    window.document.documentElement.webkitRequestFullScreen();
  };

  const handleSwitchPOS = () => {
    handleClose();
    navigate(ROUTES.SELECT_POS);
  };

  return (
    <DrawerStyle
      onClose={handleClose}
      variant={'temporary'}
      width={drawerWidth}
      open={state}
    >
      <Box
        sx={{
          py: 2,
          px: 2,
          color: 'white',
          background: (theme) => theme.palette.primary.main,
        }}
      >
        {/* <Logo /> */}
        <Profile />
      </Box>
      <Menu onClose={handleClose} />
      <Divider />
      <List>
        <ListItem button onClick={handleFullScreen}>
          <ListItemIcon>
            <FullscreenIcon />
          </ListItemIcon>
          <ListItemText>{t('fullscreen')}</ListItemText>
        </ListItem>
        <ListItem button onClick={handleSwitchPOS}>
          <ListItemIcon sx={{ px: 0.5 }}>
            <FaCashRegister />
          </ListItemIcon>
          <ListItemText>{t('switch-pos')}</ListItemText>
        </ListItem>
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>{t('logout')}</ListItemText>
        </ListItem>
      </List>
    </DrawerStyle>
  );
};

export default DrawerComponent;
