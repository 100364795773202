import { TextField } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';
import useRootStyle from './utils/useRootStyle';

const RHFNumberInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = '',
    variant = 'outlined',
    size = 'small',
    label,
    decimalValue = true,
    fullWidth = true,
    textAlign = 'left',
    className = '',
    component: Component = TextField,
    rules,
    helperTextAlign = 'left',
    min = 0,
    max,
    onChange,
    ...textFieldProps
  } = props;

  const classes = useRootStyle({ textAlign, helperTextAlign });

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: ucChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, min, max, ...rules },
    defaultValue,
  });

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    ucChange(value);
  };

  return (
    <Component
      className={`${classes.root} ${className}`}
      variant={variant}
      required={required}
      fullWidth={fullWidth}
      size={size}
      value={value}
      type='number'
      label={label}
      onChange={onChange ? (e) => onChange(e, ucChange) : handleChange}
      inputRef={ref}
      error={!!error}
      inputProps={{
        step: decimalValue ? 0.001 : 1,
        min,
        max,
        ...textFieldProps.inputProps,
      }}
      {...inputProps}
      {...textFieldProps}
    />
  );
};

export default RHFNumberInput;
