import { configureStore } from '@reduxjs/toolkit';
import cartReducer, { cartListenerMiddleware } from './cart/cartSlice';
import { loadCartStateFromLocalStorage } from './cart/utils';

const preload = () => {
  console.log('run');
  return loadCartStateFromLocalStorage();
};

export const store = configureStore({
  reducer: {
    cart: cartReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().prepend(cartListenerMiddleware.middleware);
  },
  preloadedState: preload(),
});
