import useDialog from 'src/hook/useDialog';
import { useSelector } from 'react-redux';
import { selectCustomer } from 'src/redux/cart/cartSlice';
import { IconButton } from '@mui/material';
import { FiUserCheck, FiUserPlus } from 'react-icons/fi';
import CustomerDialog from 'src/components/CustomerDialog';

const Customer = ({ fullScreen, render }) => {
  const { dialogState, openDialog, closeDialog } = useDialog();
  const customerInfo = useSelector(selectCustomer);
  const handleOpenDialog = () => openDialog(customerInfo);
  return (
    <>
      {render ? (
        render(customerInfo, handleOpenDialog)
      ) : (
        <IconButton color='inherit' onClick={handleOpenDialog}>
          {customerInfo ? <FiUserCheck /> : <FiUserPlus />}
        </IconButton>
      )}
      <CustomerDialog
        open={dialogState.open}
        onClose={closeDialog}
        fullScreen={fullScreen}
        customerInfo={dialogState.data}
      />
    </>
  );
};

export default Customer;
