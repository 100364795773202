import React, { createContext, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from 'src/api';
import Prompt, { usePrompt } from 'src/components/Prompt';
import Spinner, { useSpinner } from 'src/components/Spinner';
import { resetCarts } from 'src/redux/cart/cartSlice';
import { ROUTES } from 'src/routes';

export const AuthContext = createContext({
  user: null,
  loading: true,
  logout: () => {},
  getUser: () => {},
});

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { promptState, openPrompt, closePrompt } = usePrompt();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await API.AUTH.getUser();
      setUser(response.data ?? null);
    } catch (error) {
      console.log(error);
      openPrompt({
        title: 'get-user-failed',
        message: 'get-user-failed-message',
        buttons: [
          {
            text: t('retry'),
            onClick: async () => {
              closePrompt();
              openSpinner({ title: t('loading') });
              await getUser();
              closeSpinner();
            },
          },
          { text: t('logout'), onClick: logout },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const logout = async () => {
    try {
      await API.AUTH.logout();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(resetCarts());
      navigate(ROUTES.LOGIN);
    }
  };

  const value = { user, loading, logout, getUser };

  return (
    <AuthContext.Provider value={value}>
      {children}
      <Prompt state={promptState} backdropClose={false} />
      <Spinner state={spinnerState} />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContextProvider;
