import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import ShippingAddressListDialog from './ShippingAddressListDialog';
import useDialog from 'src/hook/useDialog';
import { ShippingAddressItem } from './ShippingAddressList';
import { useSelector } from 'react-redux';
import { selectCustomerShippingInfo } from 'src/redux/cart/cartSlice';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

const CustomerInfo = ({ fullScreen, data }) => {
  const { t } = useTranslation();

  const shippingInfo = useSelector(selectCustomerShippingInfo);
  const { openDialog, closeDialog, dialogState } = useDialog();

  const info = [
    {
      icon: <LocalPhoneIcon />,
      value: data?.phone || t('n/a'),
    },
    {
      icon: <EmailIcon />,
      value: data?.email || t('n/a'),
    },
  ];

  const handleOpenDialog = () => {
    openDialog(data?._id);
  };

  return (
    <Grid container direction='column' spacing={1} mt={1}>
      <Grid item container justifyContent='center'>
        <Avatar sx={{ width: '80px', height: '80px' }} />
      </Grid>
      <Grid item container justifyContent='center'>
        <Typography variant='h6'>
          <b>{data?.fullname}</b>
        </Typography>
      </Grid>
      <Grid item xs>
        <List>
          {info.map((item, index) => {
            return (
              <ListItem key={index}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.value} />
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid item>
        <Typography
          variant='h6'
          sx={{ display: 'inline-flex', ml: 2 }}
          gutterBottom
        >
          {t('shipping-info')}
          <FmdGoodIcon color='primary' />
        </Typography>
        <Paper
          sx={{
            position: 'relative',
            mx: 2,
            border: (theme) => `1px solid ${theme.palette.primary.light}`,
          }}
        >
          <ShippingAddressItem
            onClick={handleOpenDialog}
            receiver={shippingInfo.receiver}
            phone={shippingInfo.phone}
            address={shippingInfo.location}
          />
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handleOpenDialog}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Paper>
      </Grid>
      <ShippingAddressListDialog
        fullScreen={fullScreen}
        customerId={dialogState.data}
        open={dialogState.open}
        onClose={closeDialog}
      />
    </Grid>
  );
};

export default CustomerInfo;
