import {
  Avatar,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillCartFill } from 'react-icons/bs';
import { calculateItemsSubtotal } from 'src/redux/cart/utils';
import NumberFormat from '../NumberFormat';
import SwipeableListItem from '../SwipeableListItem';
import DeleteIcon from '@mui/icons-material/Delete';

const HoldCartListItem = ({ data, onClick, onRemove }) => {
  const { t } = useTranslation();
  const total = useMemo(() => {
    return calculateItemsSubtotal(data.items) + data.shippingFee;
  }, [data]);

  return (
    <SwipeableListItem
      divider
      button
      onClick={onClick}
      renderActions={() => {
        return (
          <IconButton title='delete' onClick={onRemove} color='secondary'>
            <DeleteIcon />
          </IconButton>
        );
      }}
    >
      <ListItemAvatar>
        <Avatar>
          <BsFillCartFill />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`Cart - ${data.items.length} item${
          data.items.length > 1 ? 's' : ''
        }`}
        secondary={`${t('customer')}: ${data.customer?.fullname ?? t('n/a')}`}
      />
      <Grid container direction='column' style={{ width: 'auto' }}>
        <Grid item container justifyContent='flex-end'>
          <NumberFormat value={total} />
        </Grid>
      </Grid>
    </SwipeableListItem>
  );
};

export default HoldCartListItem;
