import { Dialog, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitleBar from 'src/components/PageTitleBar';
import HoldCartList from './HoldCartList';

const HoldingCartDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  const matchMD = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      onClose={onClose}
      fullWidth
      fullScreen={!matchMD}
    >
      <Grid container direction='column' sx={{ height: '100vh' }}>
        <Grid item>
          <PageTitleBar title={t('hold-carts')} onClose={onClose} />
        </Grid>
        <Grid item xs>
          <HoldCartList onClose={onClose} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default HoldingCartDialog;
