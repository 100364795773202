import { Grid, Typography } from '@mui/material';
import React from 'react';

const LabelContainer = ({
  label,
  htmlFor,
  children,
  spacing = 1,
  required,
}) => {
  return (
    <Grid container direction='column' spacing={spacing}>
      <Grid item>
        <label htmlFor={htmlFor}>
          <Typography variant='body2' color='primary'>
            <b>{label}</b>
            <span style={{ color: 'red' }}>{required && '*'}</span>
          </Typography>
        </label>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default LabelContainer;
