import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageTitleBar from '../PageTitleBar';

const DialogFormContainer = ({
  open,
  onClose,
  onClick,
  title,
  formId,
  buttonProps,
  buttonText,
  icon,
  children,
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      {...otherProps}
    >
      <PageTitleBar
        title={title}
        icon={icon}
        buttonProps={buttonProps}
        onClick={onClick}
        onClose={onClose}
        formId={formId}
        buttonText={t('save')}
      />
      <Box m={2}>{children}</Box>
    </Dialog>
  );
};

export default DialogFormContainer;
