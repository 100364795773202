import { useTranslation } from 'react-i18next';
import DialogFormContainer from '../DialogFormContainer';
import CustomerForm from './CustomerForm';

const CustomerFormDialog = ({
  open,
  data,
  onClose,
  closeParent,
  fullScreen,
}) => {
  const { t } = useTranslation();
  return (
    <DialogFormContainer
      open={open}
      onClose={onClose}
      formId='create-customer-form'
      title={t('create-customer')}
      fullScreen={fullScreen}
    >
      <CustomerForm
        open={open}
        onClose={onClose}
        data={data}
        closeParent={closeParent}
      />
    </DialogFormContainer>
  );
};

export default CustomerFormDialog;
