import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthProvider';
import { ROUTES } from 'src/routes';

const POSValidator = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Box sx={{ color: 'white', width: '100%' }}>
        <LinearProgress color='inherit' />
      </Box>
    );
  }

  if (!user?.warehouse?.feature?.pos || !user?.pos?.id) {
    return <Navigate to={ROUTES.SELECT_POS} />;
  }

  return children;
};

export default POSValidator;
