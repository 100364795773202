import common from './common.json';
import login from './login.json';
import sale from './sale.json';
import selectPos from './select-pos.json';

export const TRANSLATIONS_EN = {
  ...common,
  ...login,
  ...sale,
  ...selectPos,
};
