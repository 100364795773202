import axios from './custom-axios';

const ORDER = {
  create: (newOrder) => {
    return axios.post('orders', newOrder);
  },
  getAll: async (config) => {
    return await axios.get('orders', config);
  },
  getById: async (id) => {
    return await axios.get(`orders/${id}`);
  },
  returnItems: async (orderId, data) => {
    return await axios.post(`orders/return/${orderId}`, data);
  },
  createPayment: async (orderId, data) => {
    return await axios.post(`orders/${orderId}/payments`, data);
  },
  addShippingExpense: async (orderId, data) => {
    return await axios.post(`orders/${orderId}/shipping-expense`, data);
  },
};

export default ORDER;
