import AUTH from './auth';
import STOCK from './stock';
import CUSTOMER from './customer';
import ORDER from './order';
import PAYMENT_ACCOUNTS from './payment-accounts';
import POS from './pos';

const API = { AUTH, STOCK, CUSTOMER, ORDER, PAYMENT_ACCOUNTS, POS };

export default API;
