import { useEffect, useState } from 'react';
import get from 'lodash.get';
import { useDebounceCallback } from '@react-hook/debounce';

const defaultInfiniteListState = {
  search: '',
  data: [],
  totalDocs: 0,
  page: 1,
};

const useInfiniteScrollList = ({
  config = { dataField: null, totalDocsField: null },
  request = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const [infiniteList, setInfiniteList] = useState(defaultInfiniteListState);

  useEffect(() => {
    getData({
      search: infiniteList.search,
      page: infiniteList.page,
    });
  }, [infiniteList.page, infiniteList.search]);

  const getData = async ({ search, page, combine = true }) => {
    try {
      setLoading(true);
      const response = await request({ search, page });

      const responeData = get(response.data, config?.dataField, response.data);

      setInfiniteList((prev) => {
        let data = responeData ?? [];

        if (combine) {
          data = [...prev.data, ...data];
        }

        return {
          ...prev,
          data: data,
          totalDocs: get(
            response.data,
            config?.totalDocsField ?? 'totalDocs',
            0
          ),
        };
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const refresh = () => {
    setLoading(true);

    const { totalDocs, page, data } = defaultInfiniteListState;
    setInfiniteList((prev) => ({ ...prev, totalDocs, page, data }));

    getData({
      search: infiniteList.search,
      page,
      combine: false,
    });
  };

  const fetchMore = () => {
    setInfiniteList((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const search = (search) => {
    setInfiniteList((prev) => ({ ...prev, search, page: 1, data: [] }));
  };

  const debounceSearch = useDebounceCallback((value) => {
    search(value);
  }, 500);

  return {
    loading,
    search: debounceSearch,
    fetchMore,
    totalDocs: infiniteList.totalDocs,
    data: infiniteList.data,
    refresh,
  };
};

export default useInfiniteScrollList;
