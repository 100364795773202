import React, { useEffect, useState, useCallback } from 'react';
import { TextField, Grid, Typography, Autocomplete } from '@mui/material';
import axios from 'axios';
import debounce from 'debounce';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { nanoid } from 'nanoid';

const GooglePlaceSearchInput = React.forwardRef(
  ({ label, placeholder = '', value, required, onChange, ...other }, ref) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useState('');

    useEffect(() => {
      //create session
      setSession(nanoid());
    }, []);

    //   get place autocomplete
    const search = (searchText) => {
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/google-map/place-autocomplete?search=${searchText}&sessiontoken=${session}`
        )
        .then((response) => {
          setList(response.data?.predictions || []);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    };

    //   get place detail
    const getDetail = async (placeId) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/google-map/place-detail?id=${placeId}&sessiontoken=${session}`
        );
        //   renew session
        setSession(nanoid());
        return response.data;
      } catch (err) {
        console.log(err);
        return {};
      }
    };

    const debounceSearch = useCallback(
      debounce((text) => {
        search(text);
      }, 500),
      [session]
    );

    return (
      <Autocomplete
        options={list}
        filterOptions={(option) => option}
        value={value}
        {...other}
        getOptionLabel={(option) => {
          return option?.description;
        }}
        onChange={async (e, value) => {
          if (value) {
            try {
              const { description, place_id } = value;
              const data = await getDetail(place_id);
              if (data?.result) {
                onChange({
                  description,
                  place_id,
                  location: data?.result?.geometry?.location,
                });
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            onChange(value);
          }
        }}
        loading={loading}
        onInputChange={(event, text) => {
          if (text && event?.type === 'change') {
            setLoading(true);
            debounceSearch(text);
          } else {
            setList([]);
          }
        }}
        renderOption={(props, { structured_formatting }) => {
          return (
            <Grid container alignItems='center' spacing={1} {...props}>
              <Grid item>
                <LocationOnIcon />
              </Grid>
              <Grid item xs>
                <Typography>
                  <b>{structured_formatting?.main_text}</b>
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              required={required}
              inputRef={ref}
              {...params}
              variant='standard'
              size='small'
              placeholder={placeholder}
            ></TextField>
          );
        }}
      ></Autocomplete>
    );
  }
);

export default GooglePlaceSearchInput;
