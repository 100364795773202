import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

const DateTime = () => {
  const [dateTime, setDateTime] = useState(
    dayjs().format('ddd DD MMM YYYY h:mm:ss A')
  );
  useEffect(() => {
    const interval = setInterval(() => {
      const time = dayjs().format('ddd DD MMM YYYY h:mm:ss A');
      setDateTime(time);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return <Typography>{dateTime}</Typography>;
};

export default DateTime;
