import { Grid, List } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  removeHoldCart,
  selectActiveCart,
  selectCartItems,
  selectCartList,
  selectHoldCart,
} from 'src/redux/cart/cartSlice';
import HoldCartListItem from './HoldCartIListtem';
import Prompt, { usePrompt } from '../Prompt';

const HoldCartList = ({ onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cartList = useSelector(selectCartList);
  const cartItems = useSelector(selectCartItems);
  const activeCartIndex = useSelector(selectActiveCart);
  const { promptState, openPrompt, closePrompt } = usePrompt();

  const selectNewHoldCart = (cartIndex, removeCurrentCart = false) => {
    onClose();
    dispatch(selectHoldCart({ cartIndex, removeCurrentCart }));
  };

  const handleSelectHoldCart = (cartIndex) => {
    if (cartItems.length > 0) {
      openPrompt({
        title: t('select-hold-cart-title'),
        message: t('select-hold-cart-message'),
        buttons: [
          {
            text: t('hold'),
            onClick: () => {
              selectNewHoldCart(cartIndex);
              closePrompt();
            },
          },
          {
            text: t('remove'),
            onClick: () => {
              selectNewHoldCart(cartIndex, true);
              closePrompt();
            },
          },
          { text: t('cancel'), onClick: () => closePrompt() },
        ],
      });
    } else {
      selectNewHoldCart(cartIndex, true);
    }
  };

  const handleDeleteHoldCart = (cartIndex) => {
    openPrompt({
      title: t('delete-hold-cart-title'),
      message: '',
      buttons: [
        {
          text: t('confirm'),
          onClick: () => {
            dispatch(removeHoldCart(cartIndex));
            if (cartList.length === 2) {
              onClose();
            }
            closePrompt();
          },
        },
        { text: t('cancel'), onClick: () => closePrompt() },
      ],
    });
  };

  return (
    <Grid
      container
      direction='column'
      sx={{ overflow: 'hidden' }}
      height={'100%'}
    >
      <Grid item xs sx={{ overflow: 'auto' }}>
        <List disablePadding>
          {cartList.map((cart, index) => {
            if (index === activeCartIndex) return null;
            return (
              <HoldCartListItem
                data={cart}
                key={cart.id}
                onClick={() => handleSelectHoldCart(index)}
                onRemove={() => handleDeleteHoldCart(index)}
              />
            );
          })}
        </List>
      </Grid>
      <Prompt state={promptState} />
    </Grid>
  );
};

export default HoldCartList;
