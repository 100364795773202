import calculateDiscount from 'src/utils/calculate-discount';
import { loadLocalStorage } from 'src/utils/local-storage';
import { initialState, LOCAL_STORAGE_CARTS } from './cartSlice';

export const calculateItemsSubtotal = (cartItems) => {
  return cartItems.reduce((total, product) => {
    total += calculateDiscount(
      product.salePrice * product.qty,
      product.discount
    );
    return total;
  }, 0);
};

export const loadCartStateFromLocalStorage = () => {
  const state = loadLocalStorage({ key: LOCAL_STORAGE_CARTS }) ?? initialState;

  return { cart: state };
};
