import { Avatar, Grid, Typography } from '@mui/material';
import { useAuth } from 'src/contexts/AuthProvider';

const Profile = ({ name, pos }) => {
  const { user } = useAuth();
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Avatar src={user?.user?.id?.profile?.src} />
      </Grid>
      <Grid item>
        <Typography>
          <b>{user?.user.fullname}</b>
        </Typography>
        <Typography variant='body2'>{user?.pos?.name}</Typography>
      </Grid>
    </Grid>
  );
};
export default Profile;
