import { Grid, List, Typography } from '@mui/material';
import React from 'react';
import API from 'src/api';
import InfiniteList from 'src/components/InfiniteList';
import useInfiniteScrollList from 'src/hook/useInfiniteScrollList';
import { addCustomer } from 'src/redux/cart/cartSlice';
import { useDispatch } from 'react-redux';
import CustomerListItem, { CustomerListItemSkeleton } from './CustomerListItem';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useFindProductByCode from 'src/hook/useFindProductByCode';
import CustomerSearchInput from './CustomerSearchInput';

const CustomerList = ({ onClose, handleOpenCustomerForm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const findProductByCode = useFindProductByCode();

  const {
    search,
    data: customers,
    totalDocs,
    fetchMore,
    loading,
  } = useInfiniteScrollList({
    request: ({ search, page }) => {
      return API.CUSTOMER.getAll({
        params: {
          search,
          page,
          limit: 15,
        },
      });
    },
    config: { dataField: 'customers' },
  });

  const handleClick = (customer) => () => {
    dispatch(addCustomer(customer));
    onClose();
  };

  const handleSearch = (e) => {
    search(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      findProductByCode(e.target);
    }
  };

  return (
    <Grid
      container
      direction='column'
      sx={{ overflow: 'hidden' }}
      height={'100%'}
    >
      <Grid item>
        <Box position='relative'>
          <CustomerSearchInput
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            onAddNewCustomer={handleOpenCustomerForm}
            placeholder={t('search-customer')}
          />
        </Box>
      </Grid>
      <Grid item xs id='customerList' sx={{ overflow: 'auto' }}>
        <InfiniteList
          dataLength={customers.length}
          fetchMore={fetchMore}
          totalDocs={totalDocs}
          scrollableTarget='customerList'
        >
          <List disablePadding>
            {customers.map((customer, index) => {
              return (
                <CustomerListItem
                  key={index}
                  data={customer}
                  onClick={handleClick(customer)}
                />
              );
            })}
            {loading && customers.length === 0
              ? Array(5)
                  .fill('')
                  .map((_, index) => <CustomerListItemSkeleton key={index} />)
              : null}
            {!loading && customers.length === 0 ? (
              <Typography align='center' sx={{ my: 2 }} color='GrayText'>
                {t('no-customer-found')}
              </Typography>
            ) : null}
          </List>
        </InfiniteList>
      </Grid>
    </Grid>
  );
};

export default CustomerList;
