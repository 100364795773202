import axios from './custom-axios';

const STOCK = {
  getAll: (config) => {
    return axios.get('stocks', config);
  },
  findByCode: (code) => {
    return axios.get('stocks/getProduct', { params: { code } });
  },
};

export default STOCK;
