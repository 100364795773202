import { Button, Grid, TextField } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useController } from 'react-hook-form';
import useRootStyle from './utils/useRootStyle';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
  button: {
    fontSize: '1.3rem',
    height: '3.5rem',
  },
}));

const RHFQtyInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = '',
    variant = 'outlined',
    size = 'small',
    label,
    decimalValue = false,
    fullWidth = true,
    placeholder = label?.toUpperCase(), //using label as default value
    textAlign = 'left',
    className = '',
    rules,
    helperTextAlign = 'left',
    min = 0,
    max,
    leftButtonProps,
    rightButtonProps,
    onChange,
    ...textFieldProps
  } = props;

  const rootClasses = useRootStyle({ textAlign, helperTextAlign });

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: ucChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, min, max, ...rules },
    defaultValue,
  });

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    ucChange(value);
  };

  const classes = useStyles();

  const handleIncrease = () => {
    const maxNumber = max ?? value + 1;
    if (Number(value) < maxNumber) {
      ucChange(Number(value) + 1);
    }
  };

  const handleDecrease = () => {
    if (Number(value) > min) {
      ucChange(Number(value) - 1);
    }
  };

  return (
    <Grid item container alignItems="flex-end" spacing={2}>
      <Grid item>
        <Button
          disabled={min === value}
          color="primary"
          onClick={handleDecrease}
          className={classes.button}
          size="large"
          variant="contained"
          disableElevation
          {...leftButtonProps}
        >
          <RemoveIcon />
        </Button>
      </Grid>
      <Grid item xs>
        <TextField
          className={`${rootClasses.root} ${className}`}
          variant={variant}
          required={required}
          fullWidth={fullWidth}
          size={size}
          value={value}
          type="number"
          onChange={onChange ? (e) => onChange(e, ucChange) : handleChange}
          inputRef={ref}
          placeholder={placeholder}
          error={!!error}
          {...textFieldProps}
          inputProps={{
            step: decimalValue ? 0.001 : 1,
            min,
            max,
            style: { textAlign: 'center' },
            ...textFieldProps.inputProps,
          }}
          {...inputProps}
        />
      </Grid>
      <Grid item>
        <Button
          disabled={max === value}
          onClick={handleIncrease}
          color="primary"
          className={classes.button}
          size="large"
          variant="contained"
          disableElevation
          {...rightButtonProps}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default RHFQtyInput;
