import { Dialog, Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PageTitleBar from 'src/components/PageTitleBar';
import useDialog from 'src/hook/useDialog';
import { removeCustomer } from 'src/redux/cart/cartSlice';
import CustomerFormDialog from './CustomerFormDialog';
import CustomerInfo from './CustomerInfo';
import CustomerList from './CustomerList';

const CustomerDialog = ({ open, onClose, fullScreen, customerInfo }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { dialogState, openDialog, closeDialog } = useDialog();
  const [customer, setCustomer] = useState(null);

  const handleRemoveCustomer = () => {
    setCustomer(null);
    dispatch(removeCustomer());
  };

  const handleOpenCustomerForm = (value = null) => {
    openDialog(value);
  };

  const onEntering = () => {
    setCustomer(customerInfo);
  };

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      fullScreen={fullScreen}
      TransitionProps={{ onEntering }}
    >
      <Grid container direction='column' sx={{ height: '100vh' }}>
        <Grid item>
          <PageTitleBar
            title={t('customer')}
            onClose={onClose}
            buttonText={customer && t('remove')}
            buttonProps={{
              onClick: customer && handleRemoveCustomer,
            }}
          />
        </Grid>
        <Grid item xs>
          {customer ? (
            <CustomerInfo fullScreen={fullScreen} data={customer} />
          ) : (
            <CustomerList
              onClose={onClose}
              handleOpenCustomerForm={handleOpenCustomerForm}
            />
          )}
        </Grid>
      </Grid>
      <CustomerFormDialog
        fullScreen={fullScreen}
        open={dialogState.open}
        data={dialogState.data}
        onClose={closeDialog}
        closeParent={onClose}
      />
    </Dialog>
  );
};

export default CustomerDialog;
