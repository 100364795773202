import MenuIcon from '@mui/icons-material/Menu';
import Profile from './Profile';
import DateTime from 'src/components/DateTime';
import Customer from 'src/components/Customer';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'src/routes';
import { useSelector } from 'react-redux';
import { selectTotalHoldCarts } from 'src/redux/cart/cartSlice';
import HoldingCartDialog from 'src/components/HoldCartDialog';
import useDialog from 'src/hook/useDialog';
import { BsFillCartFill } from 'react-icons/bs';

const {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  useMediaQuery,
  Badge,
} = require('@mui/material');

const Navbar = ({ handleOpen }) => {
  const { dialogState, openDialog, closeDialog } = useDialog();
  const matchMD = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const totalHoldCarts = useSelector(selectTotalHoldCarts);

  const location = useLocation();

  const isRouteSale = location.pathname === ROUTES.SALE;

  return (
    <AppBar position='static'>
      <Toolbar
        sx={{
          padding: {
            xs: 0,
            md: '0 24px',
          },
        }}
      >
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <IconButton color='inherit' onClick={handleOpen}>
              <MenuIcon />
            </IconButton>
            {isRouteSale && totalHoldCarts > 0 && (
              <IconButton color='inherit' onClick={openDialog}>
                <Badge badgeContent={totalHoldCarts} color='error'>
                  <BsFillCartFill />
                </Badge>
              </IconButton>
            )}
          </Grid>
          <Grid item sx={() => ({ display: { xs: 'none', md: 'block' } })}>
            <DateTime />
          </Grid>
          <Grid item xs={6} md={2} container justifyContent='flex-end'>
            {matchMD ? <Profile /> : isRouteSale && <Customer fullScreen />}
          </Grid>
        </Grid>
      </Toolbar>
      <HoldingCartDialog open={dialogState.open} onClose={closeDialog} />
    </AppBar>
  );
};

export default Navbar;
