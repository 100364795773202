import { useDebounceCallback } from '@react-hook/debounce';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import API from 'src/api';
import { addCartProduct } from 'src/redux/cart/cartSlice';

const useFindProductByCode = () => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const findByCode = async (input, callback) => {
    const code = input?.value ? input.value.trim() : input.trim();
    if (!code) return;

    try {
      const response = await API.STOCK.findByCode(code);
      const product = response.data?.product;
      const productName = product?.variant?.name;
      dispatch(addCartProduct(product));
      enqueueSnackbar(`"${productName}" ${t('added')}`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`"${code}" ${t('not-found')}`, { variant: 'error' });
      console.log(error);
    } finally {
      if (input?.value) {
        input.value = '';
        callback();
      }
    }
  };

  const debounceFindByCode = useDebounceCallback((input, callback) => {
    findByCode(input, callback);
  }, 300);

  return debounceFindByCode;
};

export default useFindProductByCode;
