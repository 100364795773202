const globalStyle = (theme) => ({
  /* change style scroll bar - Dont work on firefox */
  /* width */
  '*::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  '*': {
    scrollbarWidth: 'thin',
  },
  /* Track */
  '* ::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  /* Handle */
  '* ::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '10px',
  },

  // global classname
  '& .text-ellipsis': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '& .line-clamp': {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },

  '& .swiper-wrapper': {
    display: 'flex',
    alignItems: 'center',
  },
});

export default globalStyle;
