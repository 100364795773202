import {
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Chip,
  Grid,
} from '@mui/material';
import React from 'react';
import buildAddress from 'src/utils/build-address';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCustomerShippingInfo } from 'src/redux/cart/cartSlice';

const ShippingAddressList = ({ list, primaryShipping, onChange }) => {
  const selectedShippingInfo = useSelector(selectCustomerShippingInfo);

  return (
    <List>
      {list?.map((item, index) => (
        <ShippingAddressItem
          key={index}
          onClick={() => onChange(item)}
          isPrimary={index === primaryShipping}
          isSelected={item._id === selectedShippingInfo?._id}
          receiver={item.receiver}
          phone={item.phone}
          divider={true}
          address={item.location}
        />
      ))}
    </List>
  );
};

export const ShippingAddressItem = ({
  receiver,
  phone,
  address,
  isPrimary,
  isSelected,
  onClick,
  divider,
}) => {
  const { t } = useTranslation();
  return (
    <ListItemButton
      dense
      onClick={onClick}
      divider={divider}
      alignItems='flex-start'
    >
      <ListItemText
        primary={receiver}
        secondary={
          <React.Fragment>
            <Typography variant='body2' color='text.secondary'>
              {phone}
            </Typography>
            <Typography
              className='line-clamp'
              variant='caption'
              color='text.primary'
              sx={{ minHeight: '3.3em' }}
            >
              {buildAddress(address)}
            </Typography>
            <Grid container spacing={isPrimary ? 1 : 0}>
              <Grid item>
                {isPrimary && (
                  <Chip label={t('primary')} size='small' color='primary' />
                )}
              </Grid>
              <Grid item>
                {isSelected && (
                  <Chip
                    label={t('selected')}
                    size='small'
                    color='primary'
                    variant='outlined'
                  />
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        }
      />
    </ListItemButton>
  );
};
export default ShippingAddressList;
