import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

// Parent have to be overflow:auto
// Parent id = InfiniteScroll props scrollableTarget in order to trigger fetchMore
const InfiniteList = ({
  dataLength,
  totalDocs,
  fetchMore,
  children,
  ...otherProps
}) => {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={fetchMore}
      hasMore={dataLength < totalDocs}
      {...otherProps}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteList;
