export const loadLocalStorage = ({ key }) => {
  try {
    const serializeValue = localStorage?.getItem(key);
    if (serializeValue === null) {
      return null;
    }
    return JSON.parse(serializeValue);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const saveToLocalStorage = ({ key, value }) => {
  try {
    const serializeValue = JSON.stringify(value);
    localStorage?.setItem(key, serializeValue);
  } catch (error) {
    console.log(error);
  }
};

export const deleteFromLocalStorage = ({ key }) => {
  try {
    localStorage?.removeItem(key);
  } catch (error) {
    console.log('Unable to remove item.');
  }
};
