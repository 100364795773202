import {
  createListenerMiddleware,
  createSelector,
  createSlice,
  current,
  isAnyOf,
} from '@reduxjs/toolkit';
import generateId from 'src/utils/generate-id';
import { saveToLocalStorage } from 'src/utils/local-storage';
import { calculateItemsSubtotal } from './utils';

const initialCartValue = {
  items: [],
  customer: null,
  shippingInfo: null,
  shippingFee: 0,
  id: Date.now(),
};

export const initialState = {
  list: [initialCartValue],
  active: 0,
};

export const LOCAL_STORAGE_CARTS = 'carts';

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addCartProduct: (state, action) => {
      const newProduct = action.payload;
      const currentCart = state.list[state.active];

      const foundIndex = current(currentCart.items).findIndex((item) =>
        new RegExp(`^${newProduct._id}`).test(item.id)
      );

      if (foundIndex === -1) {
        const newItem = {
          variant: newProduct,
          qty: 1,
          salePrice: newProduct?.salePrice ?? 0,
          discount: newProduct?.discount
            ? { ...newProduct.discount }
            : { amount: 0, type: 'percentage' },
          id: generateId(newProduct?._id), //prevent duplicate key
        };

        currentCart.items.push(newItem);
      } else {
        currentCart.items[foundIndex].qty++;
      }
    },
    duplicateCartProduct: (state, action) => {
      const currentCart = state.list[state.active];
      const duplicateIndex = action.payload;
      const newProduct = current(currentCart.items[duplicateIndex]);
      const newItem = {
        variant: newProduct.variant,
        qty: 1,
        salePrice: newProduct?.salePrice ?? 0,
        discount: newProduct?.discount || { amount: 0, type: 'percentage' },
        id: generateId(newProduct?.id), //prevent duplicate key
      };
      currentCart.items.push(newItem);
    },
    addShippingFee: (state, action) => {
      const currentCart = state.list[state.active];
      currentCart.shippingFee = action.payload;
    },
    updateCartProduct: (state, action) => {
      const currentCart = state.list[state.active];
      const { updateIndex, updateData } = action.payload;

      // remove discount body if modify on discount with _id
      if (
        updateData.discount?._id &&
        updateData.discount?.amount !==
          current(currentCart.items[updateIndex]).discount?.amount
      ) {
        updateData.discount = {
          amount: updateData.discount.amount,
          type: updateData.discount.type,
        };
      }

      currentCart.items[updateIndex] = {
        ...currentCart.items[updateIndex],
        ...updateData,
      };
    },
    removeCartProduct: (state, action) => {
      const currentCart = state.list[state.active];
      const removeIndex = action.payload;
      currentCart.items.splice(removeIndex, 1);
    },
    clearCart: (state) => {
      const currentCart = state.list[state.active];
      currentCart.items = [];
      currentCart.customer = null;
      currentCart.shippingFee = 0;
    },
    addCustomer: (state, action) => {
      const currentCart = state.list[state.active];
      currentCart.customer = action.payload;
      const shippingInfo =
        action.payload.shippingInfo?.[action.payload?.primaryShipping];
      currentCart.shippingInfo = shippingInfo;
    },
    removeCustomer: (state) => {
      const currentCart = state.list[state.active];
      currentCart.customer = null;
    },
    updateOrderCustomerShippingInfo: (state, action) => {
      const currentCart = state.list[state.active];
      currentCart.shippingInfo = action.payload;
    },
    holdCart: (state) => {
      state.list.push({ ...initialCartValue, id: Date.now() });
      state.active = state.list.length - 1;
    },
    selectHoldCart: (state, action) => {
      let selectCartIndex = action.payload.cartIndex;
      const removeCurrentCart = action.payload.removeCurrentCart ?? false;

      if (removeCurrentCart) {
        state.list = [...current(state.list)].filter(
          (_, index) => index !== state.active
        );
        // readjust the index after remove
        selectCartIndex =
          selectCartIndex > state.active ? --selectCartIndex : selectCartIndex;
      }

      state.active = selectCartIndex;
    },
    removeHoldCart: (state, action) => {
      const removeHoldCartIndex = action.payload;
      state.list = [...current(state.list)].filter(
        (_, index) => index !== removeHoldCartIndex
      );

      // readjust the index after remove
      let activeIndex = Number(state.active);
      state.active =
        removeHoldCartIndex < activeIndex ? --activeIndex : activeIndex;
    },
    resetCarts: (state) => {
      state.list = [initialCartValue];
      state.active = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addCartProduct,
  removeCartProduct,
  updateCartProduct,
  clearCart,
  addCustomer,
  removeCustomer,
  addShippingFee,
  updateOrderCustomerShippingInfo,
  duplicateCartProduct,
  holdCart,
  selectHoldCart,
  resetCarts,
  removeHoldCart,
} = cartSlice.actions;

export default cartSlice.reducer;

// selector
const selectCartItems = (state) => {
  return state.cart.list[state.cart.active].items;
};

const selectCartSubTotal = createSelector([selectCartItems], (cartItems) => {
  return calculateItemsSubtotal(cartItems);
});

const selectCartList = (state) => {
  return state.cart.list;
};

const selectActiveCart = (state) => state.cart.active;

const selectTotalHoldCarts = (state) => state.cart.list.length - 1;

const selectCartShippingFee = (state) => {
  return state.cart.list[state.cart.active].shippingFee;
};

const selectCartTotal = createSelector(
  [selectCartSubTotal, selectCartShippingFee],
  (cartSubTotal, cartShippingFee) => {
    return Number((Number(cartSubTotal) + Number(cartShippingFee)).toFixed(3));
  }
);

const selectCustomerShippingInfo = (state) =>
  state.cart.list[state.cart.active].shippingInfo;

const selectRielExchange = () => {
  return 4100;
};

const selectCustomer = (state) => {
  return state.cart.list[state.cart.active].customer;
};

export {
  selectCartShippingFee,
  selectCustomerShippingInfo,
  selectCartItems,
  selectCartSubTotal,
  selectCartTotal,
  selectRielExchange,
  selectCustomer,
  selectTotalHoldCarts,
  selectCartList,
  selectActiveCart,
};

/**
 * listener
 */
export const cartListenerMiddleware = createListenerMiddleware();

cartListenerMiddleware.startListening({
  matcher: isAnyOf(
    addCartProduct,
    removeCartProduct,
    updateCartProduct,
    clearCart,
    addCustomer,
    removeCustomer,
    addShippingFee,
    updateOrderCustomerShippingInfo,
    duplicateCartProduct,
    holdCart,
    selectHoldCart,
    resetCarts
  ),
  effect: async (action, listenerApi) => {
    // after every action save to state to localStorage
    const state = listenerApi.getState();
    saveToLocalStorage({
      key: LOCAL_STORAGE_CARTS,
      value: state.cart,
    });
  },
});
