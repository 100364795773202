import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseSnackbarButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton color='inherit' onClick={() => closeSnackbar(id)}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseSnackbarButton;
