import { TextField, Button, Grid } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';
import useRootStyle from './utils/useRootStyle';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
  button: {
    fontSize: '1.3rem',
    height: '3.5rem',
  },

  root: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '4px',
    },
  },
}));

const discountType = {
  percentage: '%',
  currency: '$',
};

const RHFDiscountInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = { amount: 0, type: 'percentage' },
    variant = 'outlined',
    size = 'small',
    label,
    fullWidth = true,
    placeholder = label?.toUpperCase(), //using label as default value
    className = '',
    textAlign,
    helperTextAlign,
    min = 0,
    rules,
    disabled,
    ...textFieldProps
  } = props;

  const rootClasses = useRootStyle({ textAlign, helperTextAlign });

  const classes = useStyles();

  // using react hook form useController as value state
  const {
    field: {
      ref,
      value = { amount: 0, type: 'percentage' },
      onChange,
      ...inputProps
    },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, ...rules },
    defaultValue,
  });

  return (
    <Grid container alignItems='flex-end' spacing={2}>
      <Grid item xs>
        <TextField
          className={`${classes.root} ${rootClasses.root} ${className}`}
          variant={variant}
          required={required}
          fullWidth={fullWidth}
          size={size}
          value={value?.amount}
          type='number'
          onChange={(e) => onChange({ ...value, amount: e.target.value })}
          inputRef={ref}
          placeholder={placeholder}
          error={!!error}
          inputProps={{
            min,
            step: 'any',
            ...textFieldProps.inputProps,
            onFocus: (e) => {
              if (e.target.value === '0') {
                onChange({ ...value, amount: '' });
              }
            },
            onBlur: (e) => {
              if (e.target.value === '') {
                onChange({ ...value, amount: 0 });
              }
            },
          }}
          disabled={disabled}
          {...textFieldProps}
          {...inputProps}
        />
      </Grid>
      <Grid item xs='auto'>
        <Button
          disabled={disabled}
          classes={{
            root: classes.button,
          }}
          onClick={() =>
            onChange({
              ...value,
              type: value?.type === 'percentage' ? 'currency' : 'percentage',
            })
          }
          disableElevation
          variant='contained'
          color='primary'
          size='large'
        >
          {discountType[value?.type]}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RHFDiscountInput;
