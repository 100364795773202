import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
/* follow react router routes style (Required AccessControl Component)
 menuConfig consist of 2 element 
  - basePath <String> : root path of every path - ex: dashboard
  - menu <Array Object> : list of path 
        - label <String> : Name of the label
        - path <String> : path url ( will take basePath combine with current path)
        - index <Boolean> : if it is root of the path. if your basePath is dashboard and you want to use /dashboard as your path (index:true)
        - exact <Boolean> : active only if the current url match exact path
        - icon <React Component> : icon to display
        - access Array[execute,feature] : hide or show tab base on role check with accessControl 
        - children <Array Object> : if menu have submenu have access to all object above except icon and children

*/
// menu Config translation use namespace so it wont affect with other (menu.json)

const menuConfig = {
  basePath: 'pos',
  menu: [
    {
      label: 'sale',
      path: 'sale',
      exact: true,
      icon: DashboardIcon,
    },
    {
      label: 'receipt',
      path: 'receipt',
      exact: true,
      icon: ReceiptLongIcon,
    },
  ],
};

export default menuConfig;
