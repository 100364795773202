import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from '@mui/material';
import React from 'react';

const CustomerListItem = ({ data, onClick }) => {
  return (
    <ListItem onClick={onClick} divider button>
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText
        primary={data.fullname}
        secondary={`${[data.phone, data.email]
          .filter((item) => !!item)
          .join(', ')}`}
      />
    </ListItem>
  );
};

export const CustomerListItemSkeleton = () => {
  return (
    <ListItem divider sx={{ padding: '16px 12px' }}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <Skeleton variant='circular' width='40px' height='40px'></Skeleton>
        </Grid>
        <Grid item xs>
          <Skeleton width='100%' height='30px'></Skeleton>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default CustomerListItem;
