import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const PageTitleBar = ({
  icon,
  onClose,
  onClick,
  title,
  buttonProps,
  formId,
  buttonText = '',
  children,
}) => {
  return (
    <AppBar position='static'>
      <Toolbar>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <IconButton edge='start' color='inherit' onClick={onClose}>
              <Icon icon={icon} />
            </IconButton>
          </Grid>
          <Grid item xs sx={{ overflow: 'hidden' }}>
            <Typography className='text-ellipsis' variant='h6'>
              {title}
            </Typography>
          </Grid>
          {children}

          {buttonText ? (
            <Grid item>
              <Button
                sx={{ padding: 0, minWidth: 0 }}
                type='submit'
                color='inherit'
                onClick={onClick}
                form={formId}
                {...buttonProps}
              >
                {buttonText}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const Icon = ({ icon = 'close' }) => {
  const iconList = {
    close: <CloseIcon />,
    back: <ArrowBackIcon />,
  };
  if (typeof icon === 'string') {
    return iconList[icon];
  }

  return icon;
};

export default PageTitleBar;
