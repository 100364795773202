import { Button, Divider, Grid, InputBase } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const CustomerSearchInput = ({ onAddNewCustomer, ...props }) => {
  const inputRef = useRef();
  const { t } = useTranslation();

  return (
    <Grid container width='100%' direction='column'>
      <Grid item>
        <InputBase
          inputRef={inputRef}
          fullWidth
          sx={{
            marginLeft: '12px',
            '& input': {
              padding: '23.5px 0',
            },
          }}
          {...props}
        />
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <Button
          onClick={() => onAddNewCustomer(inputRef.current.value)}
          fullWidth
          size='large'
        >
          {t('add-new-customer')}
        </Button>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default CustomerSearchInput;
