import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import LabelContainer from 'src/components/LabelContainer';
import { RHFTextInput } from 'src/components/RHFControledInput';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import RHFPhoneInput from '../RHFControledInput/PhoneInput';
import GooglePlaceSearchInput from '../GooglePlaceSearchInput';
import API from 'src/api';
import useMessage from 'src/hook/useMessage';
import { useDispatch } from 'react-redux';
import { addCustomer } from 'src/redux/cart/cartSlice';

const useStyles = makeStyles(() => ({
  form: {
    '& input': {
      fontSize: '1.5rem',
    },
  },
}));

const CustomerForm = ({ open, onClose, data, closeParent }) => {
  const classes = useStyles();
  const methods = useForm();
  const { t } = useTranslation();
  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();
  const dispatch = useDispatch();

  const onSubmit = async (formData) => {
    try {
      const customerInfo = {
        firstname: formData.name,
        phone: formData.phone,
        shippingInfo: [
          {
            receiver: formData.name,
            phone: formData.phone,
            location: formData.location,
            isPrimary: true,
          },
        ],
        address: formData.address,
      };

      const response = await API.CUSTOMER.create(customerInfo);
      dispatch(addCustomer(response.data.customer));
      showSuccessResponseMessage(response);
      closeParent();
      onClose();
    } catch (error) {
      console.log(error);
      showErrorResponseMessage(error);
    }
  };

  // take search input to prefill in the customer create form
  // base on string format phone or other
  useEffect(() => {
    if (open) {
      const inputData = data.trim();
      if (!inputData) return;
      if (isPhoneNumber(inputData)) {
        methods.reset({ phone: inputData });
      } else {
        methods.reset({ name: inputData });
      }
    }
  }, [data, open]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        id='create-customer-form'
        className={classes.form}
      >
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <LabelContainer required htmlFor='name-input' label={t('name')}>
              <RHFTextInput
                name='name'
                id='name-input'
                variant='standard'
                fullWidth
                required
              />
            </LabelContainer>
          </Grid>
          <Grid item>
            <LabelContainer required htmlFor='phone-input' label={t('phone')}>
              <RHFPhoneInput
                required
                name='phone'
                id='phone-input'
                variant='standard'
                fullWidth
              />
            </LabelContainer>
          </Grid>
          <Grid item>
            <LabelContainer
              required
              htmlFor='address-input'
              label={t('address')}
            >
              <RHFTextInput
                required
                name='location.address'
                id='address-input'
                variant='standard'
                fullWidth
                rows={3}
                multiline
              />
            </LabelContainer>
          </Grid>
          <Grid item>
            <LabelContainer htmlFor='nearby-input' label={t('nearby')}>
              <Controller
                name='location.nearby'
                defaultValue={null}
                control={methods.control}
                render={({ field: { value, onChange, ref } }) => {
                  return (
                    <GooglePlaceSearchInput
                      value={value}
                      ref={ref}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </LabelContainer>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

const isPhoneNumber = (string) => {
  return /^[0-9+ ]+$/.test(string);
};

export default CustomerForm;
