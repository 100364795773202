import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const AuthRoute = ({ auth = false, children, redirect, ...rest }) => {
  let isAuth = false;
  if (Cookies.get('userpos') === 'true') {
    isAuth = true;
  }

  return isAuth !== auth ? children : <Navigate to={redirect} />;
};

export default AuthRoute;
