import React, { useState } from 'react';
import { Box, styled } from '@mui/system';
import Navbar from './Navbar';
import Drawer from './Drawer';

const RootContainer = styled('div')({
  maxHeight: '100vh',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const drawer = {
  fullWidth: '280px',
  minWidth: '90px',
};

const DashboardLayout = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <RootContainer>
      <Drawer
        state={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        drawerWidth={drawer.fullWidth}
      />
      <Navbar handleOpen={handleOpen} />
      <Box
        sx={{
          px: { xs: 0, md: 3 },
          overflow: 'hidden',
          flexGrow: 1,
          display: 'flex',
          background: (theme) => ({ md: theme.palette.primary.main }),
        }}
      >
        {children}
      </Box>
    </RootContainer>
  );
};

export default DashboardLayout;
