import ReactNumberFormat from 'react-number-format';
import { ceilToTwoDecimal } from 'src/utils/ceil-to-two-decimal';

const NumberFormat = ({ value, format = 'dollar', ceil = true, ...other }) => {
  const formatOption = {
    dollar: {
      prefix: '$',
      thousandSeparator: true,
      fixedDecimalScale: true,
      decimalScale: 2,
    },
    currency: {
      prefix: '$',
      thousandSeparator: true,
      fixedDecimalScale: true,
      decimalScale: 2,
    },
    riel: {
      thousandSeparator: true,
      suffix: '៛',
    },
    percentage: {
      suffix: '%',
      fixedDecimalScale: true,
      decimalScale: 2,
    },
    normal: {
      thousandSeparator: true,
    },
    phone: {
      format: '### ### ### ###',
    },
  };

  return (
    <ReactNumberFormat
      decimalScale={formatOption[format]?.decimalScale}
      fixedDecimalScale={formatOption[format]?.fixedDecimalScale}
      value={ceil ? ceilToTwoDecimal(value) || 0 : value || 0}
      displayType='text'
      format={formatOption[format]?.format}
      thousandSeparator={formatOption[format]?.thousandSeparator}
      prefix={formatOption[format]?.prefix}
      suffix={formatOption[format]?.suffix}
      {...other}
    />
  );
};

export default NumberFormat;
