import { makeStyles } from '@mui/styles';

const useRootStyle = makeStyles((theme) => ({
  root: {
    '& div.MuiInputBase-root': {
      background: 'white',
    },
    '& input': {
      textAlign: ({ textAlign }) => textAlign,
    },
    '& p.MuiFormHelperText-root': {
      textAlign: ({ helperTextAlign }) => helperTextAlign,
      color: theme.palette.grey[800],
    },
  },
}));

export default useRootStyle;
