import { LinearProgress } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthRoute from 'src/components/AuthRoute';
import AuthContextProvider from 'src/contexts/AuthProvider';
import DashboardLayout from 'src/layout/Dashboard';
import POSValidator from 'src/components/POSValidator';

const LoginPage = lazy(() => import('src/pages/login'));
const SalePage = lazy(() => import('src/pages/sale'));
const SelectPOSPage = lazy(() => import('src/pages/select-pos'));
const ReceiptPage = lazy(() => import('src/pages/receipt'));

export const ROUTES = {
  POS: '/pos',
  SALE: '/pos/sale',
  LOGIN: '/login',
  SELECT_POS: '/pos/select',
};

const ROUTES_PATH = [
  {
    path: 'login',
    element: (
      <AuthRoute auth={true} redirect={ROUTES.SALE}>
        <Suspense fallback={<LinearProgress />}>
          <LoginPage />
        </Suspense>
      </AuthRoute>
    ),
  },
  {
    path: 'pos/*',
    exact: true,
    element: (
      <AuthRoute auth={false} redirect={ROUTES.LOGIN}>
        <AuthContextProvider>
          <DashboardLayout>
            <Suspense fallback={<LinearProgress />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthContextProvider>
      </AuthRoute>
    ),
    children: [
      {
        path: 'sale',
        element: (
          <POSValidator>
            <SalePage />
          </POSValidator>
        ),
        exact: true,
      },
      {
        path: 'receipt',
        element: (
          <POSValidator>
            <ReceiptPage />
          </POSValidator>
        ),
        exact: true,
      },
      {
        path: 'select',
        element: <SelectPOSPage />,
        exact: true,
      },
      { path: '*', element: <Navigate to={ROUTES.SALE} /> },
    ],
  },
  { path: '*', element: <Navigate to={ROUTES.LOGIN} /> },
];

export default ROUTES_PATH;
