import { Dialog, Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import API from 'src/api';
import { updateOrderCustomerShippingInfo } from 'src/redux/cart/cartSlice';
import PageTitleBar from '../PageTitleBar';
import ShippingAddressList from './ShippingAddressList';

const ShippingAddressListDialog = ({
  open,
  customerId,
  fullScreen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState(null);
  const dispatch = useDispatch();

  const handleUpdateShippingInfo = (newShippingInfo) => {
    dispatch(updateOrderCustomerShippingInfo(newShippingInfo));
    onClose();
  };

  const onEntering = async () => {
    try {
      const response = await API.CUSTOMER.getById(customerId);
      setCustomer(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      fullScreen={fullScreen}
      TransitionProps={{ onEntering }}
    >
      <Grid container direction='column' sx={{ height: '100vh' }}>
        <Grid item>
          <PageTitleBar title={t('shipping-info')} onClose={onClose} />
        </Grid>
        <Grid item xs>
          <ShippingAddressList
            onChange={handleUpdateShippingInfo}
            list={customer?.shippingInfo}
            primaryShipping={customer?.primaryShipping}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default ShippingAddressListDialog;
