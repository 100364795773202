import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import LogoImage from 'src/assets/images/logo.png';

const Logo = ({ drawerWidth }) => {
  return (
    <Box
      sx={{
        minWidth: drawerWidth,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Avatar
        variant="square"
        src={LogoImage}
        sx={{
          width: '150px',
          '& img': { objectFit: 'contain' },
        }}
      />
    </Box>
  );
};

export default Logo;
