import axios from './custom-axios';

const CUSTOMER = {
  findOne: (value) => {
    return axios.get('customers/get', { params: { value } });
  },
  getAll: async (config) => {
    return await axios.get('customers', config);
  },
  create: async (newCustomer) => {
    return await axios.post('customers', newCustomer);
  },
  getById: (customerId, value) => {
    return axios.get(`customers/${customerId}`, { params: { value } });
  },
};

export default CUSTOMER;
