import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import { useRoutes } from 'react-router-dom';

import ROUTES_PATH from './routes';

function App() {
  const ROUTES = useRoutes(ROUTES_PATH);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {ROUTES}
    </ThemeProvider>
  );
}

export default App;
